<template>
  <ProjectLayout @cancel="returnToProjects" @back="$emit('step', 'project_data_load')"
                 :forward="project.data.forwardStep" @forward="$emit('step', 'project_training')">
    <template #mainContent>
      <div class="grid formgrid mt-2 mb-2 p-fluid">
        <div class="field col-8">
          <div class="col-12">
            <iframe :src="dataSources.data.dashboardUrl" width="100%" height="350" frameborder="0"></iframe>
          </div>
        </div>
        <div class="field col-4">

          <div class="col-12 md:col-12 lg:col-12 xl:col-10 col-offset-0 md:col-offset-0 lg:col-offset-0 xl:col-offset-2 mt-3">
            <div class="field-checkbox">
              <Checkbox id="recommendation" name="recommendation" :modelValue="true" :binary="true" class="p-disabled"/>
              <label for="recommendation">{{ t('autocbm.projects.management.data.select.form.label.recommendation') }}</label>
              <Badge value="i" class="ml-2" v-tooltip.bottom="t('autocbm.projects.management.data.select.form.help.recommendation')"/>
            </div>
            <div class="field-checkbox">
              <Checkbox id="autoDataPreprocessing" name="autoDataPreprocessing" :modelValue="true" :binary="true" class="p-disabled"/>
              <label for="autoDataPreprocessing">{{ t('autocbm.projects.management.data.select.form.label.autoDataPreprocessing') }}</label>
              <Badge value="i" class="ml-2" v-tooltip.bottom="t('autocbm.projects.management.data.select.form.help.autoDataPreprocessing')"/>
            </div>

            <Button :label="t('autocbm.projects.management.data.select.form.label.manuelDataPreprocessing')"
                    icon="pi pi-chevron-right" iconPos="right"
                    class="p-button-outlined" disabled v-tooltip.top="t('autocbm.dev.form.label.notImplemented')"/>
            <Button :label="t('autocbm.projects.management.data.select.form.label.labeling')"
                    icon="fas fa-tag" iconPos="right"
                    class="p-button-outlined mt-3" disabled v-tooltip.top="t('autocbm.dev.form.label.notImplemented')"/>
            <Panel collapsed="true" :header=" t('autocbm.projects.management.data.grafana.help.usage.header')" class="ml-2 mt-2" :toggleable="true">
              <p>{{ t('autocbm.projects.management.data.grafana.help.usage.description') }}</p>
            </Panel>
            <Panel collapsed="true" :header="t('autocbm.projects.management.data.grafana.help.label.header')" class="ml-2 mt-2" :toggleable="true">
              <p>{{ t('autocbm.projects.management.data.grafana.help.label.description') }}</p>
            </Panel>
            <Button icon="pi pi-window-maximize" @click="dataSources.data.visibleFull = true" :label="t('autocbm.projects.management.data.grafana.fullscreen.button.text')" class="ml-2 mt-2 p-button-primary"/>
            <Sidebar v-model:visible="dataSources.data.visibleFull" :baseZIndex="1000" position="full">
              <iframe :src="dataSources.data.dashboardUrl" width="100%" height="600" frameborder="0"></iframe>
              <div class="grid formgrid">
                <div class="col-12 mb-6 lg:col-6 lg:col-6">
                  <Panel collapsed="true" :header=" t('autocbm.projects.management.data.grafana.help.usage.header')" class="ml-2 mt-2" :toggleable="true">
                    <p>{{ t('autocbm.projects.management.data.grafana.help.usage.description') }}</p>
                  </Panel>
                </div>
                <div class="col-12 mb-6 lg:col-6 lg:col-6">
                  <Panel collapsed="true" :header="t('autocbm.projects.management.data.grafana.help.label.header')" class="ml-2 mt-2" :toggleable="true">
                    <p>{{ t('autocbm.projects.management.data.grafana.help.label.description') }}</p>
                  </Panel>
                </div>
              </div>
            </Sidebar>
          </div>
        </div>
      </div>
    </template>
    <template #detailsContent>
      <div class="grid p-fluid">
        <div class="col-12">
          <Message severity="info" :closable="false" class="mb-6">{{ t('autocbm.projects.management.data.select.description') }}</Message>
          <DataTable :value="dataSources.data.dataSetsDetailsValues" v-model:selection="project.data.variables" dataKey="variable" stripedRows
                     :paginator="true" :rows="dataSources.data.rows" :rowsPerPageOptions="dataSources.data.rowsOptions"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :currentPageReportTemplate="t('autocbm.projects.management.data.select.form.pagination')">
            <Column v-if="!(dataSources.data.dataSetsDetailsLoading || project.data.readonly)" selectionMode="multiple" headerStyle="width: 3em"/>
            <Column field="variable" :header="t('autocbm.projects.management.data.select.form.label.variable', 1)">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="10rem"/>
              </template>
            </Column>
            <Column field="variableType" :header="t('autocbm.projects.management.data.select.form.label.variableType')">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="10rem"/>
              </template>
            </Column>
            <Column field="minimum" :header="t('autocbm.projects.management.data.select.form.label.minimum')">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="10rem"/>
              </template>
            </Column>
            <Column field="maximum" :header="t('autocbm.projects.management.data.select.form.label.maximum')">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="10rem"/>
              </template>
            </Column>
            <Column field="mean" :header="t('autocbm.projects.management.data.select.form.label.mean')">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="10rem"/>
              </template>
              <template v-else #body="{ data: value }">
                {{ displayNumber(value.mean) }}
              </template>
            </Column>
            <Column field="median" :header="t('autocbm.projects.management.data.select.form.label.median')">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="10rem"/>
              </template>
              <template v-else #body="{ data: value }">
                {{ displayNumber(value.median) }}
              </template>
            </Column>
            <Column field="standardDeviation" :header="t('autocbm.projects.management.data.select.form.label.standardDeviation')">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="10rem"/>
              </template>
              <template v-else #body="{ data: value }">
                {{ displayNumber(value.standardDeviation) }}
              </template>
            </Column>
            <Column field="measuringPointsCount" :header="t('autocbm.projects.management.data.select.form.label.measuringPointsCount')">
              <template v-if="dataSources.data.dataSetsDetailsLoading" #body>
                <Skeleton height="1.25rem" width="5rem"/>
              </template>
            </Column>
            <template #paginatorstart>
              <DispatchStatus :loading="dataSources.data.dataSetsDetailsLoading" :saving="project.data.variablesSaving"
                              :saved="project.data.variablesSaved" :error="project.data.variablesError" textIcon text/>
            </template>
            <template #paginatorend></template>
          </DataTable>
        </div>
      </div>
    </template>
  </ProjectLayout>
</template>

<script>
import ProjectLayout from '../../ProjectLayout'

import {reactive, computed, watch, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {useDispatcher} from '@/dockone/components/dispatcher'
import {useMessage} from '@/dockone/app/message'
import {ceil} from 'lodash'
import find from 'lodash/find'

export default {
  name: 'SelectData',
  components: {ProjectLayout},
  setup() {
    const store = useStore()
    const router = useRouter()
    const {t} = useI18n()
    const {dispatches, handleDispatch, handleUpdate} = useDispatcher(['variables'])
    const message = useMessage()

    const sourceData = reactive({
      dataSetsDetails: computed(() => store.getters['autocbm/data/dataSetsDetails'] || []),
      dataSetsDetailsLoading: false,
      dataSetsDetailsValues: computed(() => {
        if (sourceData.dataSetsDetails && sourceData.dataSetsDetails.data) {
          return sourceData.dataSetsDetails.data
        } else if (sourceData.dataSetsDetailsLoading) {
          return new Array(sourceData.rows)
        } else {
          return null
        }
      }),
      rows: 10,
      rowsOptions: [10, 15, 20, 25],
      variableOptions: computed(() => sourceData.dataSetsDetailsValues ? sourceData.dataSetsDetailsValues : null),
      visibleFull: false,
      dashboardUrl: computed(() => store.getters['autocbm/project/dashboardUrl'])

    })
    const sourceDataActions = {
      loadDataSetsDetails(sourceId, measurement, startDateTime, endDateTime) {
        sourceData.dataSetsDetailsLoading = true
        return store.dispatch('autocbm/data/loadDataSetsDetails', {sourceId, measurement, startDateTime, endDateTime})
            .catch(() => message.error(t('autocbm.projects.management.data.select.action.load.error.summary'),
                t('autocbm.projects.management.data.select.action.load.error.detail')))
            .finally(() => sourceData.dataSetsDetailsLoading = false)
      }
    }
    const dataSources = {data: sourceData, ...sourceDataActions}

    const projectData = reactive({
      dataSource: computed(() => store.getters['autocbm/project/dataSource']),
      measurement: computed(() => store.getters['autocbm/project/measurement']),
      startDateTime: computed(() => store.getters['autocbm/project/startDateTime']),
      endDateTime: computed(() => store.getters['autocbm/project/endDateTime']),
      variables: computed({
        get: () => {
          const variables = store.getters['autocbm/project/variables']
          return variables ? variables.map(variable => find(sourceData.dataSetsDetailsValues, {variable})) : []
        },
        set: variables => handleDispatch('variables', store.dispatch('autocbm/project/selectVariables', variables.map(e => e.variable)))
      }),
      variablesDispatch: computed(() => dispatches.get('variables')),
      variablesSaving: computed(() => projectData.variablesDispatch.pending > 0),
      variablesSaved: computed(() => !!projectData.variables && projectData.variablesDispatch.success),
      variablesError: computed(() => projectData.variablesDispatch.error),
      readonly: computed(() => store.getters['autocbm/project/readonly']),
      forwardStep: computed(() => (!projectData.variables || projectData.variables.length === 0) ? 'disabled' : null)
    })
    const projectActions = {}
    const project = {data: projectData, ...projectActions}

    const returnToProjects = () => router.push({name: 'projects'})
    const displayNumber = (value) => value ? ceil(value, 4) : null

    watch(() => dataSources.data.dataSetsDetailsValues, dataSetsDetails => {
      if ((!project.data.variables || project.data.variables.length === 0) && dataSetsDetails && dataSetsDetails.filter(e => !!e).length > 0) {
        project.data.variables = []
      }
    })
    watch(() => project.data.variables, variables => {
      handleUpdate('variables', variables)
    }, {immediate: true})
    onMounted(() => {
      dataSources.loadDataSetsDetails(project.data.dataSource, project.data.measurement, project.data.startDateTime, project.data.endDateTime)
    })

    return {t, dataSources, project, displayNumber, returnToProjects}
  }
}
</script>

<style>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #209cdf;
  }
}

.card.loading-content {
  box-shadow: none;
}
</style>
